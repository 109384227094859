import React, {
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useRef,
  useState,
} from "react";

import { VscSend } from "react-icons/vsc";

import { AssistantMessage } from "../../../libs/zustand/slices/assistantSlice";

export interface IChatInput {
  addMessage: (newAssistantMessage: AssistantMessage) => void;
  setIsIdle: Dispatch<SetStateAction<boolean>>;
}

const ChatInput: React.FC<IChatInput> = ({ addMessage, setIsIdle }) => {
  const [prompt, setPrompt] = useState("");
  const contentEditableRef = useRef<HTMLDivElement>(null);

  const handleInputChange = () => {
    if (contentEditableRef.current) {
      const html = contentEditableRef.current.innerHTML;
      setPrompt(html);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      if (e.shiftKey) return;

      e.preventDefault();
      sendMessage();
    }
  };

  const sendMessage = async () => {
    if (contentEditableRef.current) {
      addMessage({ role: "user", content: prompt });
      setIsIdle(false);
      setPrompt("");
      contentEditableRef.current.innerHTML = "";
    }
  };

  return (
    <>
      <div
        ref={contentEditableRef}
        className="w-full border border-[#9ea7e4] dark:border-[#b58ece] rounded-3xl pl-5 pr-12 py-2 text-lg resize-none focus:outline-none text-left overflow-hidden bg-[#5a69d1] dark:bg-[#b58ece] text-white"
        contentEditable="true"
        role="textbox"
        aria-multiline="true"
        style={{ minHeight: "3rem", maxHeight: "20rem", overflowY: "auto" }}
        onInput={handleInputChange}
        onKeyDown={handleKeyDown}
      />
      <div
        className="absolute inset-y-0 right-6 my-6 px-2 mx-1 rounded-full bg-[#9ea7e4] dark:bg-[#b58ece] flex items-center justify-center cursor-pointer hover:bg-gray-200 dark:hover:bg-[#824aa9]"
        onClick={() => sendMessage()}
      >
        <VscSend className="text-white" />
      </div>
    </>
  );
};

export default ChatInput;
