import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { message } from "antd";

import { useAppStore } from "../../libs/zustand/store";

import { ChatInput, TextFormatter } from "../../components";

export interface IHome {
  handleHeightChange: () => void;
}

const Home: React.FC<IHome> = ({ handleHeightChange }) => {
  const {
    addNewAssistantMessage,
    updateAssistantHistoryContent,
    assistantData,
    setSessionId,
    resetAssistantHistory,
  } = useAppStore();

  const [messageApi, contextHolder] = message.useMessage();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileNames, setFileNames] = useState<Array<string>>([]);
  const [uploaded, setUploaded] = useState(false);

  const [isGetChatHistory, setIsGetChatHistory] = useState(false);

  const [isIdle, setIsIdle] = useState(true);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (!fileInputRef.current?.files) {
      return;
    }

    messageApi.open({
      type: "loading",
      content: "Uploading file... Please wait a moment.",
      duration: 10,
    });

    const formData = new FormData();

    Array.from(fileInputRef.current.files).forEach((file) => {
      formData.append("documents", file);
    });

    fetch(
      "https://seli-backend-production.up.railway.app/assistant/addtrainingdata",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(() => {
        messageApi.open({
          type: "success",
          content: "Successfully uploaded!",
          duration: 10,
        });
        setUploaded(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleFileInputClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFileNames([]);

    if (event.target.files) {
      const files = event.target.files;

      const newFileNames = [];
      for (let i = 0; i < files.length; i++) {
        newFileNames.push(files.item(i)?.name as string);
      }

      setFileNames(newFileNames);
    }
  };

  const getAnswer = useCallback(async () => {
    const lastUserMessage =
      assistantData.assistantHistories[assistantData.index].assistantHistory[
        assistantData.assistantHistories[assistantData.index].assistantHistory
          .length - 1
      ].content;

    addNewAssistantMessage({ role: "assistant", content: "" });

    let assistantMessage: string = "";

    fetch(
      "https://seli-backend-production.up.railway.app/assistant/service_v2",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question: lastUserMessage,
          sessionId:
            assistantData.assistantHistories[assistantData.index].sessionId,
        }),
      }
    )
      .then((response) => {
        if (!response.body) {
          throw new Error("Response body is null");
        }
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        reader
          .read()
          .then(function processText({ done, value }): Promise<void> {
            if (done) return Promise.resolve();

            let data = decoder.decode(value, { stream: true });

            const tokens = JSON.parse("[" + data.replace(/}{/g, "},{") + "]");

            tokens.forEach((item: any) => {
              if (item.token) assistantMessage += item.token;
              else if (item.sessionId) setSessionId(item.sessionId);
            });

            handleHeightChange();

            updateAssistantHistoryContent(
              assistantMessage,
              assistantData.assistantHistories[assistantData.index]
                .assistantHistory.length - 1
            );

            return reader.read().then(processText);
          });
      })
      .catch((error) => console.error(error));
  }, [
    assistantData.assistantHistories,
    assistantData.index,
    addNewAssistantMessage,
    updateAssistantHistoryContent,
    setSessionId,
    handleHeightChange,
  ]);

  const getChatHistory = useCallback(async () => {
    if (
      !assistantData.assistantHistories[assistantData.index].assistantHistory
        .length
    ) {
      const response = await fetch(
        "https://seli-backend-production.up.railway.app/assistant/getallchathistory",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            sessionId:
              assistantData.assistantHistories[assistantData.index].sessionId,
          }),
        }
      );

      const responseData = await response.json();

      if (response.status === 200)
        responseData.data.forEach((item: any) => {
          let role: "user" | "assistant" = "user";

          if (item.id.includes("HumanMessage")) role = "user";
          else if (item.id.includes("AIMessage")) role = "assistant";

          addNewAssistantMessage({ role, content: item.kwargs.content });
        });
      else if (response.status === 201) resetAssistantHistory();
    }

    setIsGetChatHistory(true);
  }, [
    addNewAssistantMessage,
    resetAssistantHistory,
    assistantData.assistantHistories,
    assistantData.index,
  ]);

  useEffect(() => {
    if (!isGetChatHistory) getChatHistory();
  }, [getChatHistory, isGetChatHistory]);

  useEffect(() => {
    if (isIdle === false) {
      getAnswer();
      setIsIdle(true);
    }
  }, [isIdle, getAnswer]);

  return (
    <div className="flex-1 flex flex-col border-r md:rounded-br-md border-[#9ea7e4] dark:border-[#b58ece] justify-end">
      {contextHolder}
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          <div className="px-6 py-1 flex">
            <button
              className="border rounded-full px-4 py-1 text-white hover:bg-[#9ea7e4]"
              onClick={handleFileInputClick}
            >
              Upload training data
            </button>
          </div>
          <div className="px-6 pt-1 pb-4 flex gap-2">
            {fileNames.map((fileName, index) => (
              <span
                className="px-4 py-1 rounded-full text-white text-2 bg-[#5a69d1] dark:bg-[#61367c]"
                key={index}
              >
                {fileName}
              </span>
            ))}
            <form onSubmit={handleSubmit}>
              <input
                type="file"
                id="documents"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: "none" }}
                accept=".txt, .csv, .json"
                multiple
              />
              <input
                type="submit"
                value={uploaded ? "Uploaded!" : "Upload"}
                className={
                  !uploaded
                    ? "border rounded-full px-4 py-1 text-white cursor-pointer hover:bg-[#9ea7e4]"
                    : "rounded-full px-4 py-1 bg-white text-[#5a69d1] dark:text-[#61367c]"
                }
                style={{ display: fileNames.length || uploaded ? "" : "none" }}
                disabled={uploaded}
              />
            </form>
          </div>
        </div>
        {assistantData.assistantHistories[
          assistantData.index
        ].assistantHistory.map((message, index) => (
          <div className="flex gap-2 items-center px-4 py-1" key={index}>
            {message.role === "assistant" ? (
              <img
                alt="Seli Logo"
                src="seli_logo.png"
                height="40"
                width="40"
                className="rounded-full min-w-12 mt-1 mb-auto"
              />
            ) : (
              <img
                alt="User Logo"
                src="user.png"
                height="40"
                width="40"
                className="rounded-full min-w-12 mt-1 mb-auto"
              />
            )}
            <div className="flex flex-col gap-1">
              <p className="text-white">
                {message.role === "assistant" ? "Seli" : "User"}
              </p>
              {message.content === "" ? (
                <div className="flex gap-2 items-center">
                  <p className="text-white">Processing </p>
                  <span className="animate-ping w-2 h-2 bg-white rounded-full" />
                </div>
              ) : (
                <TextFormatter content={message.content} />
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="sticky bottom-0 flex items-center p-4 border-t border-b rounded-none md:rounded-br-md border-[#9ea7e4] dark:border-[#b58ece] backdrop-blur">
        <ChatInput addMessage={addNewAssistantMessage} setIsIdle={setIsIdle} />
      </div>
    </div>
  );
};

export default Home;
