import React from "react";

export interface ILeftSidebarTitle {}

const LeftSidebarTitle: React.FC<ILeftSidebarTitle> = () => {
  return (
    <div className="flex gap-1 top-0 w-full items-center justify-center p-4">
      <img
        alt="Seli Logo"
        src="seli_logo.png"
        height="35"
        width="35"
        className="rounded-full"
      />
      <span className="font-bold text-2xl text-white">SeliGPT</span>
    </div>
  );
};

export default LeftSidebarTitle;
