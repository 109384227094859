import React, { useState } from "react";

import { BsMoonStarsFill, BsSunFill } from "react-icons/bs";

import useDarkSide from "../../../hooks/useDarkSide";

export interface IThemeToggle {}

const ThemeToggle: React.FC<IThemeToggle> = () => {
  const { colorTheme, setTheme } = useDarkSide();
  const [darkSide, setDarkSide] = useState(
    colorTheme === "light" ? true : false
  );

  const toggleDarkMode = (checked: boolean) => {
    setTheme(colorTheme);
    setDarkSide(checked);
  };

  return (
    <div
      className="absolute right-10 top-32 border border-white rounded-full p-2 cursor-pointer hover:bg-[#9ea7e4] dark:hover:bg-[#b58ece] z-10 float-animation duration-150"
      onClick={() => toggleDarkMode(!darkSide)}
    >
      {darkSide ? (
        <BsMoonStarsFill className="text-white" size="20" />
      ) : (
        <BsSunFill className="text-white" size="20" />
      )}
    </div>
  );
};

export default ThemeToggle;
