import React from "react";

import {
  BotSelector,
  LeftSidebarMenu,
  LeftSidebarTitle,
  NewThread,
} from "../../components";

export interface ILeftSidebar {}

const LeftSidebar: React.FC<ILeftSidebar> = () => {
  return (
    <div className="hidden md:flex flex-col border border-[#9ea7e4] dark:border-[#b58ece] bg-[#5a69d1] dark:bg-[#61367c] rounded-l-lg w-64 min-w-64 overflow-auto">
      <LeftSidebarTitle />

      <div className="px-4 pb-4">
        <NewThread />
      </div>

      <div className="overflow-auto">
        <LeftSidebarMenu />

        <BotSelector />
      </div>

      <div className="sticky mt-auto p-2 flex gap-2 items-center justify-center border-t border-[#9ea7e4] dark:border-[#b58ece] w-full">
        <img
          alt="Seli Logo"
          src="seli_logo.png"
          height="40"
          width="40"
          className="rounded-full"
        />
        <p className="text-white">Seli Assistant</p>
      </div>
    </div>
  );
};

export default LeftSidebar;
