import React from "react";

export interface IHeader {}

const Header: React.FC<IHeader> = () => {
  return (
    <div className="sticky top-0 border-t border-r md:rounded-tr-md border-b border-[#9ea7e4] dark:border-[#b58ece] bg-[#9ea7e4]/50 dark:bg-[#b58ece]/50 items-center justify-between px-2 flex backdrop-blur">
      <div className="flex gap-1 items-center font-bold">
        <span>💡</span>
        <p className="hidden sm:flex text-white">General</p>
      </div>
      <span className="hidden sm:flex text-white">Seli Turbo</span>
      <div className="flex gap-1 items-center">
        <p className="text-white">Assistant</p>
        <img
          alt="Seli Logo"
          src="seli_logo.png"
          height="16"
          width="16"
          className="rounded-full"
        />
      </div>
    </div>
  );
};

export default Header;
