import React, { useRef } from "react";

import { Header } from "../";
import Routers from "../../routers/Routers";

export interface IContents {}

const Contents: React.FC<IContents> = () => {
  const contentsRef = useRef<HTMLDivElement>(null);

  const handleHeightChange = () => {
    if (contentsRef.current) {
      contentsRef.current.scrollTop = contentsRef.current.scrollHeight;
    }
  };

  return (
    <div
      ref={contentsRef}
      className="flex flex-col w-full pr-1 overflow-y-scroll border-l border-[#9ea7e4] dark:border-[#b58ece] md:border-none"
    >
      <Header />
      <Routers handleHeightChange={handleHeightChange} />
    </div>
  );
};

export default Contents;
