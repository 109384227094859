import React from "react";

import { useAppStore } from "../../../libs/zustand/store";

import BotSelectorItem from "./BotSelectorItem";

export interface IBotSelector {}

const BotSelector: React.FC<IBotSelector> = () => {
  const { assistantData } = useAppStore();

  return (
    <div className="flex flex-col gap-1 px-4">
      {assistantData.threads.map((thread, index) => (
        <BotSelectorItem name={thread} index={index} key={index} />
      ))}
    </div>
  );
};

export default BotSelector;
