import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { Home } from "../pages";

export interface IRouters {
  handleHeightChange: () => void;
}

const Routers: React.FC<IRouters> = ({ handleHeightChange }) => {
  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={<Home handleHeightChange={handleHeightChange} />}
          />
        </Routes>
      </Router>
    </>
  );
};

export default Routers;
