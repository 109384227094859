import React from "react";

export interface IMenuItem {
  icon: React.ReactNode;
  name: string;
  isSelected: boolean;
  toggleItem: (name: string) => void;
}

const MenuItem: React.FC<IMenuItem> = ({
  icon,
  name,
  isSelected,
  toggleItem,
}) => {
  return (
    <div
      // href="/"
      className={`flex gap-2 items-center px-3 py-1 rounded-full duration-150 cursor-pointer hover:bg-[#9ea7e4] dark:hover:bg-[#b58ece] ${
        isSelected ? "bg-[#9ea7e4] dark:bg-[#b58ece]" : ""
      }`}
      onClick={() => toggleItem(name)}
    >
      {icon}
      <span className="text-sm text-white">{name}</span>
    </div>
  );
};

export default MenuItem;
