import React, { useState } from "react";

import {
  FcBusinessContact,
  FcDocument,
  FcPackage,
  FcRefresh,
  FcSearch,
  FcSettings,
} from "react-icons/fc";

import { useAppStore } from "../../../libs/zustand/store";

import MenuItem from "./Item";

export interface ILeftSidebarMenu {}

const LeftSidebarMenu: React.FC<ILeftSidebarMenu> = () => {
  const { resetAssistantHistory } = useAppStore();

  const [menuItems, setMenuItems] = useState([
    {
      icon: <FcSettings />,
      name: "Settings",
      isSelected: false,
    },
    {
      icon: <FcSearch />,
      name: "Search",
      isSelected: false,
    },
    {
      icon: <FcPackage />,
      name: "Archived",
      isSelected: false,
    },
    {
      icon: <FcBusinessContact />,
      name: "Contact",
      isSelected: false,
    },
    {
      icon: <FcDocument />,
      name: "Changelog",
      isSelected: false,
    },
    {
      icon: <FcRefresh />,
      name: "Sync with database",
      isSelected: false,
    },
  ]);

  const toggleItem = (name: string) => {
    if (name === "Sync with database") {
      resetAssistantHistory();
    } else {
      const newMenuItems = menuItems.map((menuItem) => {
        if (menuItem.name === name) {
          const newMenuItem = {
            icon: menuItem.icon,
            name: menuItem.name,
            isSelected: true,
          };

          return newMenuItem;
        } else {
          const otherMenuItem = {
            icon: menuItem.icon,
            name: menuItem.name,
            isSelected: false,
          };

          return otherMenuItem;
        }
      });

      setMenuItems(newMenuItems);
    }
  };

  return (
    <div className="flex flex-col gap-1 mx-6 mb-4">
      {menuItems.map((menuItem, index) => (
        <MenuItem
          icon={menuItem.icon}
          name={menuItem.name}
          isSelected={menuItem.isSelected}
          toggleItem={toggleItem}
          key={index}
        />
      ))}
    </div>
  );
};

export default LeftSidebarMenu;
