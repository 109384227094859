import React from "react";

import { ThemeToggle } from "./components";
import { Contents, LeftSidebar } from "./layouts";

import "./App.css";

const App: React.FC = () => {
  return (
    <div className="App bg-[#7683d9] dark:bg-[#824aa9]">
      <ThemeToggle />
      <div className="flex h-full border-[#9ea7e4] dark:border-[#b58ece]">
        <LeftSidebar />
        <Contents />
      </div>
    </div>
  );
};

export default App;
