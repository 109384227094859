import React from "react";

import { BsNodePlus } from "react-icons/bs";

import { useAppStore } from "../../../libs/zustand/store";

export interface ILeftSidebar {}

const NewThread: React.FC<ILeftSidebar> = () => {
  const { assistantData, addNewThread } = useAppStore();

  const separateStringAndNumber = (input: string): [string, number | null] => {
    const match = input.match(/^(.*?)(\d+)$/);
    if (match) {
      return [match[1].trim(), parseInt(match[2])];
    } else {
      return [input, null];
    }
  };

  const handleNewThread = () => {
    const [text, index] = separateStringAndNumber(
      assistantData.threads[assistantData.index]
    );
    addNewThread(`${text} ${(index as number) + 1}`);
  };

  return (
    <div
      className="flex justify-between items-center px-4 border border-[#9ea7e4] dark:border-[#b58ece] w-full rounded-full h-10 min-h-10 duration-150 hover:bg-[#9ea7e4] dark:hover:bg-[#b58ece] hover:ring-4 focus:ring-4 focus:bg-[#9ea7e4] cursor-pointer"
      onClick={handleNewThread}
    >
      <span className="text-xs text-white">New Thread</span>
      <BsNodePlus className="text-white" />
    </div>
  );
};

export default NewThread;
