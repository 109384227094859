import React from "react";

import { useAppStore } from "../../../libs/zustand/store";

export interface IBotSelectorItem {
  name: string;
  index: number;
}

const BotSelectorItem: React.FC<IBotSelectorItem> = ({ name, index }) => {
  const { assistantData, setSelectedThread } = useAppStore();

  return (
    <div
      className={`flex gap-2 items-center rounded-xl ${
        assistantData.index === index ? "bg-[#9ea7e4] dark:bg-[#b58ece]" : ""
      } hover:bg-[#9ea7e4] dark:hover:bg-[#b58ece] duration-150 cursor-pointer p-2`}
      onClick={() => setSelectedThread(index)}
    >
      <img
        alt="Seli Logo"
        src="seli_logo.png"
        height="30"
        width="30"
        className="rounded-full"
      />
      <p className="text-white">{name}</p>
    </div>
  );
};

export default BotSelectorItem;
