import { create } from "zustand";

import { devtools, persist } from "zustand/middleware";

import { AssistantSlice, createAssistantSlice } from "./slices/assistantSlice";

type StoreState = AssistantSlice;

export const useAppStore = create<StoreState>()(
  persist(
    devtools((...props) => ({
      ...createAssistantSlice(...props),
    })),
    { name: "Seli" }
  )
);
