import { StateCreator } from "zustand";

export interface AssistantMessage {
  role: string;
  content: string;
}

export interface AssistantHistory {
  assistantHistory: Array<AssistantMessage>;
  sessionId: string;
}

export interface AssistantSlice {
  assistantData: {
    assistantHistories: Array<AssistantHistory>;
    index: number;
    threads: Array<string>;
  };
  addNewAssistantMessage: (newAssistantMessage: AssistantMessage) => void;
  updateAssistantHistoryContent: (content: string, index: number) => void;
  resetAssistantHistory: () => void;
  setSessionId: (sessionId: string) => void;
  addNewThread: (threadName: string) => void;
  setSelectedThread: (index: number) => void;
}

export const createAssistantSlice: StateCreator<AssistantSlice> = (
  set,
  get
) => ({
  assistantData: {
    assistantHistories: [
      {
        assistantHistory: [],
        sessionId: "",
      },
    ],
    index: 0,
    threads: ["Seli Assistant 1"],
  },
  addNewAssistantMessage: (newAssistantMessage: AssistantMessage) => {
    const assistantData = get().assistantData;
    assistantData.assistantHistories[assistantData.index].assistantHistory.push(
      newAssistantMessage
    );

    set({ assistantData });
  },
  updateAssistantHistoryContent: (content: string, index: number) => {
    const assistantData = get().assistantData;
    assistantData.assistantHistories[assistantData.index].assistantHistory[
      index
    ].content = content;

    set({ assistantData });
  },
  resetAssistantHistory: () => {
    const assistantData = get().assistantData;
    assistantData.assistantHistories[assistantData.index].assistantHistory = [];
    assistantData.assistantHistories[assistantData.index].sessionId = "";

    set({ assistantData });
  },
  setSessionId: (sessionId: string) => {
    const assistantData = get().assistantData;
    assistantData.assistantHistories[assistantData.index].sessionId = sessionId;

    set({ assistantData });
  },
  addNewThread: (threadName: string) => {
    const assistantData = get().assistantData;
    assistantData.threads.push(threadName);
    assistantData.assistantHistories.push({
      assistantHistory: [],
      sessionId: "",
    });
    assistantData.index += 1;

    set({ assistantData });
  },
  setSelectedThread: (index: number) => {
    const assistantData = get().assistantData;
    assistantData.index = index;

    set({ assistantData });
  },
});
