import React from "react";

export interface ITextFormatter {
  content: string;
}

const TextFormatter: React.FC<ITextFormatter> = ({ content }) => {
  const convertTextToHtml = (text: string) => {
    text = text.replace(
      /\*\*\*(.*?)\*\*\*/g,
      "<em><strong class='text-white'>$1</strong></em>"
    );

    text = text.replace(
      /\*\*(.*?)\*\*/g,
      "<strong class='text-white'>$1</strong>"
    );

    text = text.replace(/(.+?)(?=\n\n|$)/gs, "<p class='text-white'>$1</p>");

    const olRegex =
      /<p class='text-white'><strong>(\d+\..*?)<\/strong>(.*?)<\/p>/gs;
    text = text.replace(olRegex, (match, strongContent, itemContent) => {
      return `<li><p class='text-white'><strong>${strongContent}</strong>${itemContent}</p></li>`;
    });

    if (text.includes("<li>")) {
      text = text.replace(/(<li>[\s\S]*?<\/li>)/g, "<ol>$1</ol>");
    }

    text = text.replace(/\n\n/g, "<br />");
    text = text.replace(/\n/g, "<br />");

    return text;
  };

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: convertTextToHtml(content),
      }}
    />
  );
};

export default TextFormatter;
